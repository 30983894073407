import { useMutation, useQuery } from "@tanstack/react-query";
import services from "./services";
import { CUSTOMERS, ORDER } from "./enums";
import useNotification from "../../utils/useNotification";
import { useToast } from "@chakra-ui/react";
import useOrdersStore from "./store/useOrdersStore";
import { useGetOrderMediaConfig } from "../PlaceOrderTest/queryHooks";
import useMatricsStore from "./store/useMetricsStore";
import { useEffect } from "react";
import { useConfiguredQuery } from "../../config/useConfiguredQuery";

const isValidNumber = (value: any, min: number, max: number): boolean => {
  const numberValue = Number(value);
  return !isNaN(numberValue) && numberValue >= min && numberValue <= max;
};

export const useGetAllGaraazOrders = (filterString: string) => {
  const { data: mobileConfigs } = useGetOrderMediaConfig();

  const setAllOrders = useOrdersStore((state) => state.setAllOrders);
  const setOrderItems = useOrdersStore((state) => state.setOrderItems);
  const setOrderEtas = useOrdersStore((state) => state.setOrderEtas);
  const setOrderRemarks = useOrdersStore((state) => state.setOrderRemarks);

  const currentISTDate = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const currentHourInIST = new Date(currentISTDate).getHours();

  const activeHoursStart = isValidNumber(
    mobileConfigs?.active_hours_start,
    0,
    23
  )
    ? Number(mobileConfigs?.active_hours_start)
    : 7;

  const activeHoursEnd = isValidNumber(mobileConfigs?.active_hours_end, 0, 23)
    ? Number(mobileConfigs?.active_hours_end)
    : 20;

  const isValidActiveHours = activeHoursStart < activeHoursEnd;

  const finalActiveHoursStart = isValidActiveHours ? activeHoursStart : 7;
  const finalActiveHoursEnd = isValidActiveHours ? activeHoursEnd : 20;

  const isActiveHour =
    currentHourInIST >= finalActiveHoursStart &&
    currentHourInIST < finalActiveHoursEnd;

  const staleTime = isActiveHour
    ? isValidNumber(mobileConfigs?.allOrders_query_staleTime, 1, Infinity)
      ? Number(mobileConfigs?.allOrders_query_staleTime) * 60 * 1000
      : 1 * 60 * 1000
    : Infinity;

  const refetchInterval = isActiveHour
    ? isValidNumber(mobileConfigs?.allOrders_query_refetchInterval, 1, Infinity)
      ? Number(mobileConfigs?.allOrders_query_refetchInterval) * 60 * 1000
      : 5 * 60 * 1000
    : false;

  return useQuery(
    [ORDER.ALLGARAAZORDERS, filterString],
    () => services.getAllGaraazOrders(filterString),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime,
      refetchInterval,
      onSuccess: (data) => {
        setAllOrders(data);
        setOrderItems(data);
        setOrderEtas(data);
        setOrderRemarks(data);
      },
    }
  );
};

export function useGetUniqueCustomersToday(range: any) {
  const setUniqueCustomers = useMatricsStore(
    (state: any) => state.setUniqueCustomers
  );

  const { data, isFetching } = useConfiguredQuery(
    [CUSTOMERS.UNIQUECUSTOMERSTODAY, range],
    () => services.getUniqueCustomers(range),
    {
      onSuccess: (data: any) => {
        setUniqueCustomers(data.today[0]);
      },
      refetchOnWindowFocus: false,
    }
  );

  return { data, isFetching };
}

export function useGetUniqueCustomersYesterdayAndThisMonth(range: any) {
  const { data, setUniqueCustomers, isDataExpired } = useMatricsStore();
  useEffect(() => {
    if (!data || isDataExpired()) {
      services.getUniqueCustomers(range).then((response) => {
        if (response && response.yearAndMonth) {
          setUniqueCustomers(response.yearAndMonth[0], true);
        }
      });
    }
  }, [range, isDataExpired]);

  return data;
}

export const useGetSingleGaraazOrder = (orderId: string) => {
  return useQuery([ORDER.SINGLEGARAAZORDER, orderId], () => {
    return services.getSingleOrder(orderId);
  });
};

export const useSearchByPartNumber = (partNumber: string) => {
  return useQuery([partNumber], async (): Promise<any> => {
    if (partNumber.trim() === "") {
      return [];
    }

    const response = await services.searchPartWithPartNumber(partNumber.trim());
    if (response === undefined) {
      return [];
    } else {
      return response;
    }
  });
};

export const useGetOrderHandlers = () => {
  const setOrderHandlers = useOrdersStore((state) => state.setOrderHandlers);
  return useQuery([ORDER.ORDERHANDLER], services.getOrderHandlers, {
    staleTime: Infinity,
    onSuccess: (data) => {
      setOrderHandlers(data);
    },
  });
};
export const useGetAllOrderHandlers = () => {
  return useQuery([ORDER.ALLORDERHANDLERS], services.getAllOrderHandlers, {
    staleTime: Infinity,
  });
};

export const useGetOrderStatsByOrderHandler = () => {
  return useQuery(
    [ORDER.ORDERSTATSBYORDERHANDLER],
    () => {
      return services.getOrderStatsByOrderHandler();
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: 15 * 1000 * 60,
      staleTime: 15 * 1000 * 60,
      cacheTime: 30 * 1000 * 60,
    }
  );
};

export const useSplitOrder = (
  orderNo: string,
  orderId: string,
  orderItems: any,
  newOrderData: any
) => {
  const orderToast = useToast();
  const toast = useNotification();
  return useMutation(
    () => {
      return services.splitOrder(
        orderNo,
        orderId,
        { orderItems: orderItems },
        newOrderData
      );
    },

    {
      onSuccess: (data) => {
        orderToast({
          description: `New Order (${data.orderNo}) has been created successfully`,
          status: "success",
          duration: 10000,
          isClosable: true,
          position: "top",
        });
      },
      onError: (error) => {
        toast({ description: `${error}`, status: "error" });
      },
    }
  );
};

export const useGetAllSuppliers = () => {
  return useQuery([ORDER.GET_ALL_SUPPLIERS], services.getAllSuppliers, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetBrandsInfo = () => {
  return useQuery([ORDER.BRANDS_INFO], services.getBrandsInfo, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetAllMakers = () => {
  return useQuery([ORDER.CARMAKERS], services.fetchMakers, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetCustomerAllSubscriptions = (customerId: string) => {
  return useQuery(
    [customerId],
    () => services.getCustomerAllSubscriptions(customerId),
    {
      staleTime: Infinity,
    }
  );
};

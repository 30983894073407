import { Tabs, TabList, Tab, Box } from "@chakra-ui/react";

interface TabBarProps {
  onChange: (index: number) => void;
}

function CustomersTab({ onChange }: TabBarProps): JSX.Element {

  return (
    <Box>
      <Tabs isFitted defaultIndex={1} onChange={onChange}>
        <TabList mb="1em">
          <Tab>Ordered</Tab>
          <Tab>Unordered</Tab> 
        </TabList>
      </Tabs>
    </Box>
  );
}

export default CustomersTab;

import { Box, Image, Text } from "@chakra-ui/react";
import MetricsInfo from "./MetricsInfo";
import { useNavigate } from "react-router-dom";

type PerformanceCardsProps = {
	userData: any;
	index: number;
};

const PerformanceCards: React.FC<PerformanceCardsProps> = ({
	userData,
	index,
}) => {
	const navigate = useNavigate()
	const metricsData = [
		{
			label: "Handled",
			primary: userData?.orders_handled_today,
			secondary: userData?.orders_handled_total,
		},
		{
			label: "Unhandled",
			primary: userData?.orders_unhandled_total,
			highlightPrimary: true,
		},
		{
			label: "Cancelled",
			primary: userData?.orders_cancelled_today,
			secondary: userData?.orders_cancelled_total,
		},
		{
			label: "Returns",
			primary: userData?.orders_return_today,
			secondary: userData?.orders_return_total,
		},
		{
			label: "CBO",
			primary: userData?.orders_cbo_today,
			secondary: userData?.orders_cbo_total,
		},
		{
			label: "Customer Hold",
			primary: userData?.orders_customer_hold_today,
			secondary: userData?.orders_customer_hold_total,
			highlightSecondary: true,
		},
		{
			label: "Delivered",
			primary: userData?.orders_delivered_today,
			secondary: userData?.orders_delivered_total,
		},
		{
			label: "CBO Due",
			primary: userData?.CBODue_today,
			highlightPrimary: true,
		},
	];
	return (
		<Box
			minWidth={"200px"}
			backgroundImage={"linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)"}
			height={"100%"}
			display={"flex"}
			flexDirection={"column"}
			p={2}
			boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
			borderRadius={"10px"}
			zIndex={1}
			cursor={"pointer"}
			transition="transform 200ms ease-out"
			_hover={{ transform: "scale(1.02)" }}
			_active={{ transform: "scale(0.95)" }}
			onClick={() => window.open(`/metrics/${userData?.orderHandler?._id}`, '_blank')}
		>
			<Box display={"flex"} flexDirection={"column"} p={2}>
				<Box
					width={"100%"}
					display={"flex"}
					justifyContent={"space-between"}
				>
					<Box
						width={"50px"}
						height={"50px"}
						borderRadius={"50%"}
						overflow={"clip"}
					>
						<Image
							width={"100%"}
							height={"100%"}
							src={userData?.orderHandler?.picture[0]?.path}
							alt=""
							backgroundPosition={"center"}
						></Image>
					</Box>
					{index !== -1 && (
						<Box
							fontSize={"2xs"}
							fontWeight={"extrabold"}
							width={"20px"}
							height={"20px"}
							position={"relative"}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							_before={{
								content: `''`,
								position: "absolute",
								top: "50%",
								//   bottom: 0,
								width: "3px",
								height: "20px",
								background: "#ff0000",
								borderWidth: "0 3.125px",
								borderColor: "#140037",
								borderStyle: "solid",
								clipPath:
									"polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0% 100%)",
								left: 0,
								transform: "translateX(5%) rotate(30deg)",
								zIndex: -1,
							}}
							_after={{
								content: `''`,
								position: "absolute",
								top: "50%",
								//   bottom: 0,
								width: "3px",
								height: "20px",
								background: "#ff0000",
								borderWidth: "0 3.125px",
								borderColor: "#140037",
								borderStyle: "solid",
								clipPath:
									"polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0% 100%)",
								right: 0,
								transform: "translateX(-5%) rotate(-30deg)",
								zIndex: -1,
							}}
							bgColor={
								index + 1 == 1
									? "gold"
									: index + 1 == 2
									? "silver"
									: index + 1 == 3
									? "	#CD7F32"
									: "none"
							}
							border={`3px solid ${
								index + 1 == 1
									? "#fcbb07"
									: index + 1 == 2
									? "#a0a0a0"
									: index + 1 == 3
									? "#b87333"
									: "none"
							}`}
							borderRadius={"50%"}
						>
							{index + 1}
						</Box>
					)}
				</Box>

				<Box mt={2}>
					<Text fontSize={"sm"} fontWeight={"bold"}>
						{userData?.orderHandler?.firstName ?? ""}{" "}
						{userData?.orderHandler?.lastName ?? ""}
					</Text>
				</Box>
			</Box>
			<Box
				bgColor={"white"}
				flex={1}
				borderRadius={"5px"}
				mt={2}
				p={2}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<MetricsInfo
					label={"Unhandled"}
					primary={userData?.orders_unhandled_today}
					highlightPrimary={true}
					color="green"
					labelFontSize="lg"
					primaryFontSize="3xl"
					primaryFontWeight="bold"
				/>
			</Box>
			<Box
				bgColor="white"
				flex={1}
				borderRadius="5px"
				mt={2}
				p={2}
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				justifyContent="space-between"
			>
				{metricsData.map((metric, index) => (
					<MetricsInfo
						key={index}
						label={metric.label}
						primary={metric.primary}
						secondary={metric.secondary}
						highlightPrimary={metric.highlightPrimary}
						highlightSecondary={metric.highlightSecondary}
					/>
				))}
			</Box>
		</Box>
	);
};

export default PerformanceCards;

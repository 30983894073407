import { FiLayers } from "react-icons/fi";
import { HiOutlinePencil } from "react-icons/hi";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { FaCarSide } from "react-icons/fa";
const paths = {
  home: "/",
  login: "/login",
  placeOrder: "/place-order",
  cars: "/cars",
  samePartOrders: "/same-part-orders",
  engine3D: "/engine",
  metrics: "/metrics/:orderHandlerId",
  customers :"/unique-customers"
};

export const pageTitles = {
  placeOrder: "Place Order",
  orders: "Orders",
  cars: "Cars",
  samePartOrders: "Same Part Orders",
};

export const NavNames = {
  placeOrder: "Place Order",
  orders: "Orders",
  cars: "Cars",
  samePartOrders: "Same Part Orders",
};

export const pathObject = [
  {
    main: { name: pageTitles.orders },
    sub: [
      {
        name: NavNames.orders,
        path: paths.home,
        icon: <FiLayers></FiLayers>,
      },
    ],
  },
  {
    main: { name: pageTitles.placeOrder },
    sub: [
      {
        name: NavNames.placeOrder,
        path: paths.placeOrder,
        icon: <HiOutlinePencil></HiOutlinePencil>,
      },
    ],
  },
  {
    main: { name: pageTitles.cars },
    sub: [
      {
        name: NavNames.cars,
        path: paths.cars,
        icon: <FaCarSide></FaCarSide>,
      },
    ],
  },
];

export default paths;

import { useEffect, useMemo, useState } from "react";
import { COLUMN_TYPES, GaraazDateRange, GaraazTable } from "@svan-garaaz/reactsharedlib";
import CustomersTab from "./CustomersTab";
import { Box, Flex, HStack } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";

const Index = () => {
  const today = new Date().toISOString().split("T")[0];
  const [activeTab, setActiveTab] = useState(1);
  const [dateRange, setDateRange] = useState({ startDate: today, endDate: today });
  const [offset, setOffset] = useState(0); // State for offset
  const [limit, setLimit] = useState(10); // State for limit (default row count)


  const row = [10, 20, 30, 50, 100, 150];
  const value = 10;
  const authorizationAPItoken = localStorage.getItem("auth_orca") as any
  
  const TableHeading = [
    {
      header: "Customer Name",
      accessor: "name",
      type: COLUMN_TYPES.FULLNAME,
      copyable: true,
    },
    {
      header: "Code",
      accessor: "customerCode",
    },
    {
      header: "Mobile No.",
      accessor: "mobileNumber",
      type: COLUMN_TYPES.MOBILE_WITH_SIZE,
    },
    {
      header: "Last Order Date",
      accessor: "lastOrderDate",
      type: COLUMN_TYPES.DATE,
    },
    {
      header: "Days Since Last Order",
      accessor: "daysSinceLastOrder",
      type: COLUMN_TYPES.VALUE,
    },
  ];
  useEffect(() => {
    setDateRange({
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
    });
  }, []);

  function handleChange(index: any) {
    setActiveTab(index);
  }
  const tableUrl = useMemo(() => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/v2/customers/unique`;
    const queryParams = `startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    const orderedParam = activeTab === 0 ? "ordered=true" : "ordered=false";
    return `${baseUrl}?${orderedParam}&${queryParams}`;
  }, [dateRange, activeTab, offset, limit]);
  return (
    
    <PageScaffold title={"Unique Orders Customer"} isHeaderVisible={false}>
      <Flex p={5}  pt={10} gap={2} bg={"#FAFAFC"}>
      <HStack w={"100%"}  alignItems={"flex-start"}>
        <Box w={"20%"} borderRadius={"10px"} bg={"white"} pl={5} pr={5} pb={5} boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}>
          <GaraazDateRange
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onDateChange={(startDate:any, endDate:any) => {
            setDateRange({ 
              ...dateRange,
              startDate, 
              endDate 
            });
          }}
          />
        </Box>
        <Box w={"100%"} p={2}
					boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
					borderRadius={"10px"}>
          <Box>
            <CustomersTab onChange={handleChange} />
          </Box>
          {activeTab === 0 && (
            <GaraazTable
              columns={TableHeading}
              url={tableUrl}
              limits={row}
              defaultRowLimit={value}
              authorizationAPItoken={authorizationAPItoken}
            />
          )}
          {activeTab === 1 && (
            <GaraazTable
              columns={TableHeading}
              url={tableUrl}
              limits={row}
              defaultRowLimit={value}
              authorizationAPItoken={authorizationAPItoken}
            />
          )}
        </Box>
      </HStack>
      </Flex>
    </PageScaffold>
  );
};

export default Index;

import { useQuery } from "@tanstack/react-query";
import Services from "./services";

export const useGetOrderMediaConfig = () => {
  return useQuery(
    ["GET_MOBILE_CONFIG"],
    async () => {
      const response = await Services.getOrderMediaConfig();
      return {
        order_media_type: response.order_media_type,
        order_cancellation_reasons: response.order_cancellation_reasons,
        min_reason_length: response.min_reason_character_length,
        quick_etas_mins: response.quick_etas_mins,
        threshold_giving_eta_to_customer_mins:
          response.threshold_giving_eta_to_customer_mins,
        threshold_ack_order_mins: response.threshold_ack_order_mins,
        quick_etas_cbo_order_days: response.quick_etas_cbo_order_days,
        active_hours_start: response.active_hours_start,
        active_hours_end: response.active_hours_end,
        allOrders_query_staleTime: response.orca_allorders_query_stale_time_min,
        allOrders_query_refetchInterval: response.orca_allorders_query_refetch_interval_min,
        global_query_stale_time : response.orca_query_stale_time_min,
        global_query_refetch_interval : response.orca_query_refetch_interval_min
      };
    },
    { staleTime: Infinity }
  );
};

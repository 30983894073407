import { useQuery } from "@tanstack/react-query";
import { useGetOrderMediaConfig } from "../views/PlaceOrderTest/queryHooks";

interface MobileConfig {
  active_hours_start?: number;
  active_hours_end?: number;
  global_query_stale_time?: number;
  global_query_refetch_interval?: number;
}

const isValidNumber = (value: any, min: number, max: number): boolean => {
    const numberValue = Number(value);
    return !isNaN(numberValue) && numberValue >= min && numberValue <= max;
  };

export function useConfiguredQuery(queryKey: any, queryFn: any, options: any = {}) {
  const { data: mobileConfigs = {} as MobileConfig } = useGetOrderMediaConfig() || {};

  const currentHourInIST = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }).split(",")[1]?.split(":")[0];
  const [defaultStart, defaultEnd] = [7, 20];

  const activeHoursStart = isValidNumber(mobileConfigs?.active_hours_start, 0, 23)
    ? +mobileConfigs.active_hours_start
    : defaultStart;
  const activeHoursEnd = isValidNumber(mobileConfigs?.active_hours_end, 0, 23)
    ? +mobileConfigs.active_hours_end
    : defaultEnd;

  const isActiveHour = activeHoursStart < activeHoursEnd && +currentHourInIST >= activeHoursStart && +currentHourInIST < activeHoursEnd;

  const staleTime = isActiveHour
    ? (isValidNumber(mobileConfigs?.global_query_stale_time, 1, Infinity)
        ? +mobileConfigs.global_query_stale_time
        : 1) * 60 * 1000
    : Infinity;

  const refetchInterval = isActiveHour
    ? (isValidNumber(mobileConfigs?.global_query_refetch_interval, 1, Infinity)
        ? +mobileConfigs.global_query_refetch_interval
        : 5) * 60 * 1000
    : false;

  return useQuery(queryKey, queryFn, { staleTime, refetchInterval,  ...options});
}



import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

dayjs.extend(utc);
dayjs.extend(timezone);

const IST_TIMEZONE = "Asia/Kolkata";

interface MatricsStoreState {
  data: any | null;
  lastFetchDate: any;
  setUniqueCustomers: (data: any, shouldUpdateLastFetchDate: Boolean) => void;
  clearData: () => void;
  isDataExpired: () => boolean;
  getCurrentDateInIST: () => any;
}

const useMatricsStore = create<MatricsStoreState>()(
  persist(
    (set, get) => ({
      data: null,
      lastFetchDate: null,

      setUniqueCustomers: (newData, shouldUpdateLastFetchDate = false) => {
        const existingData = get().data || {};

        const mergedData = {
          ...existingData,
          ...newData,
        };
        if (shouldUpdateLastFetchDate) {
          set({
            data: mergedData,
            lastFetchDate: get().getCurrentDateInIST(),
          });
        } else {
          set({
            data: mergedData,
          });
        }
      },

      clearData: () => {
        set({
          data: null,
          lastFetchDate: null,
        });
      },

      isDataExpired: () => {
        if (!get().lastFetchDate) return true;
        const currentIST = dayjs().tz(IST_TIMEZONE);
        const lastFetchIST = dayjs(get().lastFetchDate).tz(IST_TIMEZONE);
        const diffInDays = currentIST.diff(lastFetchIST, "day");
        return diffInDays >= 1;
      },
      getCurrentDateInIST: () => {
        return dayjs().tz(IST_TIMEZONE).format("YYYY-MM-DD");
      },
    }),
    {
      name: "unique-customer",
      storage: createJSONStorage(() => localStorage),
      serialize: (state) => JSON.stringify(state),
      deserialize: (str) => JSON.parse(str),
    }
  )
);

export default useMatricsStore;

import {
  HStack,
  VStack,
  IconButton,
  Box,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

import { Key, useEffect, useState } from "react";
import { HiDownload } from "react-icons/hi";
import { globalOrderStatus, fileDownloadImage } from "../enums";
import GaraazPartConversion from "./GaraazPartConversion";
import GMainImageComponent from "./GaraazZoomer";
import ImageModal from "./ImageModal";
import RemarksModel from "./RemarksModel";
import { AllocatedTo, finalOrcaStates } from "./GaraazOrderComponent";
import useOrdersStore from "../store/useOrdersStore";
import { GaraazTextComponent } from "@svan-garaaz/reactsharedlib";

type GaraazOrderComponentBodyProps = {
  orderStatus: any;
  orderData: any;
  displayPartConversion: string;
  findBrandName: (brandId: string) => any;
  activesupplierOrderStatus: AllocatedTo;
  getSupplierBusinessName: (supplierId: string) => any;
};
const GaraazOrderComponentBody: React.FC<GaraazOrderComponentBodyProps> = ({
  orderData,
  orderStatus,
  displayPartConversion,
  findBrandName,
  activesupplierOrderStatus,
  getSupplierBusinessName,
}) => {
  const [mainImage, setMainImage] = useState(
    orderData?.orderMedia && orderData?.orderMedia.length
      ? orderData?.orderMedia[0]
      : []
  );
  const [isOpen, setIsOpen] = useState(false);
  const savedOrderRemarks = useOrdersStore(
    (state) => state.orderRemarks[orderData._id]
  );

  const {
    isOpen: isRemarksModalOpen,
    onOpen: onRemarksModalOpen,
    onClose: onRemarksModalClose,
  } = useDisclosure();
  const {
    isOpen: isSupplierRemarksModalOpen,
    onOpen: onSupplierRemarksModalOpen,
    onClose: onSupplierRemarksModalClose,
  } = useDisclosure();
  const {
    isOpen: isOrderRemarksModalOpen,
    onOpen: onOrderRemarksModalOpen,
    onClose: onOrderRemarksModalClose,
  } = useDisclosure();

  useEffect(() => {
    setMainImage(
      orderData?.orderMedia && orderData?.orderMedia.length
        ? orderData?.orderMedia[0]
        : []
    );
  }, [orderData.orderMedia, orderData]);

  function handleClickVerticalImages(Image: string | undefined) {
    setMainImage(Image || {});
  }
  function handleModalOpen() {
    const previousImage = mainImage;
    setMainImage({
      _id: "",
      userId: "",
      path: "",
      mimeType: previousImage.mimeType,
      __v: 0,
      createdAt: "",
      updatedAt: "",
    });
    setTimeout(() => {
      setMainImage(previousImage);
    }, 1);
    setIsOpen(true);
  }
  return (
    <HStack
      width={"100%"}
      height={"68%"}
      marginTop={"0"}
      style={{ marginTop: "0" }}
      padding={"10px"}
      justifyContent={"space-evenly"}
      bgColor={"white"}
    >
      <HStack
        width={"100%"}
        height={"100%"}
        display={`${displayPartConversion}`}
      >
        {orderData?.orderMedia?.length > 0 && (
          <Box width={"8%"} position={"relative"} height={"100%"}>
            <VStack
              height={"100%"}
              width={"100%"}
              overflow={"scroll"}
              position={"absolute"}
            >
              {orderData?.orderMedia?.map(
                (item: any, index: Key | null | undefined) => {
                  return item?.mimeType.startsWith("image") ? (
                    <Image
                      key={index}
                      src={item.path}
                      height={"60px"}
                      width={"100%"}
                      border={"3px solid lightgrey"}
                      onMouseEnter={() => {
                        handleClickVerticalImages(item);
                      }}
                    />
                  ) : (
                    <Image
                      key={index}
                      src={fileDownloadImage}
                      height={"60px"}
                      width={"100%"}
                      border={"3px solid lightgrey"}
                      onMouseEnter={() => {
                        handleClickVerticalImages(item);
                      }}
                    />
                  );
                }
              )}
            </VStack>
          </Box>
        )}

        {orderData?.orderMedia &&
          orderData?.orderMedia?.length > 0 &&
          mainImage?.mimeType &&
          mainImage?.mimeType?.startsWith("image") && (
            <Box height={"100%"} width={"40%"} position={"relative"}>
              <GMainImageComponent
                imagePath={mainImage.path}
                handleModalOpen={handleModalOpen}
              />
            </Box>
          )}

        {orderData?.orderMedia &&
          orderData?.orderMedia?.length > 0 &&
          !mainImage?.mimeType?.startsWith("image") && (
            <Box height={"100%"} width={"40%"}>
              <Box position={"relative"} width={"100%"} height={"100%"}>
                <Box width={"100%"} height={"100%"} position={"relative"}>
                  <Image
                    position={"absolute"}
                    style={{ marginTop: "0px" }}
                    src={fileDownloadImage}
                    width={"100%"}
                    height={"100%"}
                    alignSelf={"center"}
                  />
                </Box>

                <IconButton
                  position={"absolute"}
                  top={0}
                  right={0}
                  alignSelf={"end"}
                  aria-label="Download"
                  icon={
                    <HiDownload style={{ fontSize: "1.5em", color: "black" }} />
                  }
                  size="sm"
                  onClick={() => {
                    window.location.href = mainImage.path;
                  }}
                />
              </Box>
            </Box>
          )}

        {orderData?.orderMedia && orderData?.orderMedia?.length > 0 && (
          <ImageModal
            mediaFiles={orderData.orderMedia}
            mainImage={mainImage}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          />
        )}

        <GaraazPartConversion
          orderData={orderData}
          isDisabled={
            orderData.orderType === "RETURN" ||
            orderData.orderType === "SUBSCRIPTION" ||
            finalOrcaStates.includes(orderStatus) ||
            orderStatus === globalOrderStatus.READY_TO_DISPATCH ||
            orderStatus === globalOrderStatus.DELIVERED
          }
          orderId={orderData?._id}
          findBrandName={findBrandName}
        ></GaraazPartConversion>
      </HStack>
      <Box
        width="100%"
        display={displayPartConversion === "none" ? "flex" : "none"}
        justifyContent="flex-start"
        height="100%"
      >
        <VStack width="45%" mr={7}>
          {orderData?.customerRemarks && (
            <GaraazTextComponent
              header="Customer Remarks"
              content={orderData.customerRemarks}
              maxNoOfLines={2}
              maxNoOfCharacters={100}
            />
          )}

          <GaraazTextComponent
            header="Car Model"
            content={orderData?.carModel ?? "Not Provided"}
            maxNoOfLines={1}
          />
          <GaraazTextComponent
            header="VIN Number"
            content={orderData?.vinNumber ?? "Not Provided"}
            maxNoOfLines={1}
          />
          <GaraazTextComponent
            header="License Plate Number"
            content={orderData?.licensePlateNumber ?? "Not Provided"}
            maxNoOfLines={1}
          />

          {activesupplierOrderStatus?.orderRemarks && (
            <GaraazTextComponent
              header="Supplier Remarks"
              secondaryHeader={`[${getSupplierBusinessName(
                activesupplierOrderStatus.supplierId
              )}]`}
              content={activesupplierOrderStatus.orderRemarks}
              maxNoOfLines={2}
              maxNoOfCharacters={100}
            />
          )}
        </VStack>

        <Box
          width={"50%"}
          flex={1}
          justifySelf={"flex-start"}
          marginRight={"auto"}
          onClick={onOrderRemarksModalOpen}
          cursor={"pointer"}
          marginBottom={3}
        >
          <Text
            color={"#0096e0"}
            fontWeight={"bold"}
            style={{ marginBottom: "auto" }}
          >
            Order Remarks
          </Text>
          {savedOrderRemarks?.slice(0, 13).map((item: any, idx: any) => {
            return (
              <Box key={idx} width={"100%"} display={"flex"}>
                <Text style={{ fontWeight: "bold" }} whiteSpace={"nowrap"}>
                  {item.userName}:
                </Text>
                <Text
                  noOfLines={1}
                  color={item.userName === "SYSTEM" ? "red" : "black"}
                  ml={1}
                >
                  {item.remarks}
                </Text>
              </Box>
            );
          })}

          <Text style={{ lineHeight: "5px" }} fontWeight="bold">
            ...
          </Text>

          <RemarksModel
            isOpen={isOrderRemarksModalOpen}
            onClose={onOrderRemarksModalClose}
            isCustomerRemarks={false}
            modalHeader={"Order Remarks"}
            savedOrderRemarks={savedOrderRemarks}
          />
        </Box>
      </Box>
    </HStack>
  );
};

export default GaraazOrderComponentBody;

import { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import theme from "./Themes";
import { setupPublicAxios, setupAuthAxios } from "./config/axios";
import paths from "./utils/paths";
import globalConstants from "./utils/constants";
import Layout from "./components/Layout";

import LoginPage from "./views/Login";
import PlaceOrderTest from "./views/PlaceOrderTest";
import Orders from "./views/Orders";
import Metrics from "./views/Metrics";
import Cars from "./views/Cars";
import SamePartOrders from "./views/SamePartOrders";
import Engine3D from "./views/Engine3D";
import UniqueCustomers from "./views/uniqueCustomers";
import { UserDetailsProvider } from "./context/userDetailsContext";

setupPublicAxios(process.env.REACT_APP_BASE_URL);

export const App = () => {
  useEffect(() => {
    if (localStorage.auth) {
      const authJSON = localStorage.getItem(globalConstants.AUTH_TOKEN);
      if (authJSON) {
        setupAuthAxios(process.env.REACT_APP_BASE_URL, authJSON);
      }
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <Layout />
            </ProtectedRoutes>
          }
        >
          <Route index element={<Orders />} />
          <Route element={<Metrics />} path={paths.metrics}/>
          <Route element={<PlaceOrderTest />} path={paths.placeOrder} />
          <Route element={<Cars />} path={`${paths.cars}/*`}></Route>
          <Route element={<UniqueCustomers />} path={paths.customers} />
          <Route
            element={<SamePartOrders />}
            path={`${paths.samePartOrders}/*`}
          ></Route>
          <Route
            path={`${paths.engine3D}/*`}
            element={<Engine3D></Engine3D>}
          ></Route>
        </Route>
        <Route path={paths.login} element={<LoginPage />} />
      </Routes>
    </ChakraProvider>
  );
};

function ProtectedRoutes({ children }: { children: JSX.Element }) {
  let location = useLocation();

  if (!localStorage.getItem(globalConstants.AUTH_TOKEN))
    return <Navigate to={paths.login} state={{ from: location }} replace />;

  return <UserDetailsProvider>{children}</UserDetailsProvider>;
}

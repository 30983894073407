export enum TextLabels {
  ORDERS = "Orders",
}

export const noImageFileURL =
  "https://garaaz-store.s3.ap-south-1.amazonaws.com/12f9acad4db84044a016377125fbd8c5.jpg";
export const fileDownloadImage =
  "https://garaaz-store.s3.amazonaws.com/088189fc8241416d8a9c79cee875d4e2.png";

export const epochPlatformEtaSeconds = -19800;

export enum globalOrderStatus {
  NEW = "NEW",
  CANCELLED = "CANCELLED",
  CANCEL = "CANCEL",
  DELETED = "DELETED",
  CBO = "CBO",
  ACK = "ACK",
  READY = "READY",
  COMPLETED = "COMPLETED",
  COMPLETE = "COMPLETE",
  ALLOCATE = "ALLOCATE",
  ALLOCATED = "ALLOCATED",
  REVOKED = "REVOKED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  READY_TO_DISPATCH = "READY_TO_DISPATCH",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  CUSTOMER_RETURNED = "CUSTOMER_RETURNED",
  CUSTOMER_RETURN_REJECTED = "CUSTOMER_RETURN_REJECTED",
  SUPPLIER_RETURNED = "SUPPLIER_RETURNED",
  SUPPLIER_RETURN_REJECTED = "SUPPLIER_RETURN_REJECTED",
  DELIVERED = "DELIVERED",
  CUSTOMER_HOLD = "CUSTOMER_HOLD",
}

export function getGaraazStateColor(stateName: globalOrderStatus) {
  switch (stateName) {
    case globalOrderStatus.NEW:
      return "#92E6A7";
    case globalOrderStatus.ACK:
      return "#FF8C00";
    case globalOrderStatus.DELIVERED:
      return "#1A7431";
    case globalOrderStatus.ALLOCATED:
      return "#07BEB8";
    case globalOrderStatus.CBO:
      return "#936639";
    case globalOrderStatus.CANCELLED:
      return "#85182A";
    case globalOrderStatus.READY_TO_DISPATCH:
      return "#BFAB25";
    case globalOrderStatus.RETURN_REQUESTED:
      return "#92E6A7";
    case globalOrderStatus.CUSTOMER_RETURNED:
      return "#2DC653";
    case globalOrderStatus.CUSTOMER_RETURN_REJECTED:
      return "#E01E37";
    case globalOrderStatus.SUPPLIER_RETURNED:
      return "#1A7431";
    case globalOrderStatus.SUPPLIER_RETURN_REJECTED:
      return "#E01E37";
    case globalOrderStatus.ACCEPTED:
      return "#1A7431";
    case globalOrderStatus.REJECTED:
      return "#E01E37";
    case globalOrderStatus.CUSTOMER_HOLD:
      return "#e5383b";
  }
}

export function getSupplierStateColor(stateName: globalOrderStatus) {
  switch (stateName) {
    case globalOrderStatus.NEW:
      return { color: "#92E6A7", textColor: "black" };
    case globalOrderStatus.ACK:
      return { color: "#FF8C00", textColor: "black" };
    case globalOrderStatus.CBO:
      return { color: "#936639", textColor: "white" };
    case globalOrderStatus.REVOKED:
      return { color: "#9932CC", textColor: "white" };
    case globalOrderStatus.READY:
      return { color: "#1A7431", textColor: "white" };
    case globalOrderStatus.CANCELLED:
      return { color: "#85182A", textColor: "white" };
    case globalOrderStatus.RETURN_REQUESTED:
      return { color: "#92E6A7", textColor: "black" };
    case globalOrderStatus.ACCEPTED:
      return { color: "#1A7431", textColor: "white" };
    case globalOrderStatus.REJECTED:
      return { color: "#E01E37", textColor: "white" };
  }
}

export enum globalOrderActions {
  CUSTOMER_RETURNED = "CUSTOMER_RETURN_ACCEPT",
  CUSTOMER_RETURN_REJECTED = "CUSTOMER_RETURN_REJECT",
  SUPPLIER_RETURNED = "SUPPLIER_RETURN_ACCEPT",
  SUPPLIER_RETURN_REJECTED = "SUPPLIER_RETURN_REJECT",
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  CUSTOMER_UNHOLD = "CUSTOMER_UNHOLD",
}

export enum ORDER {
  ALLGARAAZORDERS = "ALLGARAAZORDERS",
  ORDERHANDLER = "ORDERHANDLER",
  GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS",
  BRANDS_INFO = "BRANDS_INFO",
  ACTIVE_SUBSCRIPTION_CUSTOMERS = "ACTIVE_SUBSCRIPTION_CUSTOMERS",
  SINGLEGARAAZORDER = "SINGLEGARAAZORDER",
  ORDERSTATSBYORDERHANDLER = "ORDERSTATSBYORDERHANDLER",
  ALLORDERHANDLERS = "ALLORDERHANDLERS",
  SAMEPARTORDERS = "SAMEPARTORDERS",
  CARMAKERS = "CARMAKERS",
}

export enum CUSTOMERS{
  UNIQUECUSTOMERSTODAY = "UNIQUECUSTOMERSTODAY",
  UNIQUECUSTOMERYESTERDAYANDTHISMONTH = "UNIQUECUSTOMERYESTERDAYANDTHISMONTH"
}

export enum OrdersTextLabels {
  ORDER_DELETED = "Order deleted successfully!",
  SOMETHING_WENT_WRONG = "Something went wrong",
  ERROR_ON_FILE_LOAD = "Error occurred while extracting file contents",
  INVOICE_UPLOADED_SUCCESSFULLY = "Invoice uploaded successfully!",
  CHALLAN_UPLOADED_SUCCESSFULLY = "Challan uploaded successfully",
  INVALID_PDF_CONTENT = "Please ensure that the Invoice number does not exceed 16 characters. It can contain alphabets, numerals, hyphens(-), and/or slash(/).",
  ACCESS_DENIED_ORDER_STATUS_INVALID = "Action Denied : Order State Invalid!",
  CBO_STATE_INVALID = "Action Denied : Order State Invalid or Requested Supplier State Invalid",
}

export enum CustomerType {
  PLATFORM = "PLATFORM",
  SUBSCRIBED = "Subscribed",
  PPD = "PPD",
  XPRESS = "Xpress",
}

export enum Roles {
  OPERATIONS_LEAD = "OPERATIONS_LEAD",
  EXECUTIVE = "EXECUTIVE",
}

export enum EngagementMode {
  PLATFORM = "PLATFORM",
  SUBSCRIBED = "SUBSCRIBED",
  EXPIRED = "EXPIRED",
}
